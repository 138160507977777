@charset "utf-8";
@import "./variables";
@import "./bulma";
@import "./header";
@import "./footer";
@import "./helper";

* {
  font-family: "Source Sans Pro", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  color: #000;
}

body {
  overflow-x: hidden;
}

p {
  margin: 20px 0;
}

h5 {
  font-size: 22px;
  color: $green;
  line-height: 1;
}

h1,
h2 {
  font-size: 28px;
}

.home h1 {
  font-size: 1em;
  margin: 0;
  display: inline;
}

.container {
  width: 100%;
}

.bg-with-text {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  p:first-child {
    margin-top: 0;
  }

  .overlay {
    min-height: 100vh;
    height: 100%;
    width: 100%;

    .container {
      margin: 30px auto;
      background-color: rgba($color: $white, $alpha: 0.35);
      border-radius: 30px;
    }
  }
}

.section {
  min-height: 500px;

  h1 {
    color: $green;
  }
}

.contact {
  h5 {
    margin: 20px 0 10px;
  }

  p {
    margin: 0 0 20px;
    span {
      display: block;
    }

    &.phone-number {
      a,
      a:visited,
      a:active {
        color: $green;

        svg {
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }
}

@media (min-width: $tablet) {
  .container {
    width: auto;
  }

  h1,
  h2 {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  h5 {
    font-size: 24px;
    color: $green;
    line-height: 1;
  }

  .bg-with-text,
  .bg-with-text > .overlay {
    min-height: 60vh;
  }
}

@media (min-width: $desktop) {
  body {
    overflow-x: hidden;
  }

  h1,
  h2 {
    font-size: 36px;
  }

  h5 {
    font-size: 28px;
    color: $green;
    line-height: 1;
  }

  .bg-with-text,
  .bg-with-text > .overlay {
    min-height: 70vh;
    font-size: 24px;
  }

  .home h1 {
    font-size: 24px;
    margin: 0;
    display: inline;
    font-weight: normal;
  }
}

@media (min-width: $widescreen) {
  h1,
  h2 {
    font-size: 42px;
  }

  h5 {
    font-size: 32px;
    color: $green;
    line-height: 1;
  }
}

@media (min-width: $fullhd) {
  h1,
  h2 {
    font-size: 48px;
  }
}
