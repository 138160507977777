@charset "utf-8";

header {
  .header-container {
    background-color: #fff;
    min-height: 125px;
    color: #000;
    border-top: 5px solid $green;
    padding: 10px 0;
  }

  .border {
    height: 10px;
    background-color: $green;
    margin-bottom: 20px;
  }

  .App-logo {
    width: 75px;
    height: 75px;
  }

  a,
  a:visited,
  a:active {
    color: #fff;

    svg {
      vertical-align: middle;
    }
  }

  .logo-and-title span,
  .address-phone span {
    display: block;
  }

  .logo-and-title {
    color: #000;
    width: 350px;
    margin: 0 auto;
    text-align: center;

    h1,
    h2 {
      font-size: 0.8em;
      color: $green;
    }
  }

  .address-phone {
    margin-left: auto;
    height: 150px;

    .phone-number {
      margin-left: 30px;
      height: 96px;

      a,
      a:visited,
      a:active,
      a:focus {
        color: $green;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .menu-phone {
    height: 75px;

    .navbar-burger {
      margin-left: 10px;

      span {
        background-color: #fff;
        height: 2px;
        width: 20px;
      }
    }
  }
}

.nav-bar {
  height: 80px;
  background-color: $green;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 33;
  border-top: 5px solid $green;
  border-bottom: 5px solid $green;

  .logo {
    opacity: 0;
    transition: all 0.3s;
    position: absolute;
    top: 5px;
    left: 12%;
  }

  &.stick {
    position: fixed;
    box-shadow: 0px 0 12px 1px #000;

    .logo {
      opacity: 1;
    }
  }

  .navbar-menu {
    justify-content: center;
    height: 65px;
  }
}

.navbar-item,
.navbar-item:visited {
  margin: 0 20px;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  border-bottom: 1px solid $green;

  &:hover,
  &.active {
    color: #fff;
    border-bottom: 1px solid $white;
  }
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  color: $white;
  background-color: transparent;
  border-bottom: 1px solid $white;
}

.menu-mobile {
  height: 70px;
  > div {
    height: 100%;
  }
}

.navbar-menu-mobile {
  width: 100%;
  position: fixed;
  padding-bottom: 0;
  z-index: 33;
  top: 0;
  height: 100vh;
  background-color: #fff;
  padding: 10px 0;

  .logo {
    padding: 0 15px;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .navbar-menu {
    display: block;
  }

  .navbar-item,
  .navbar-item:visited {
    margin: 0 20px;
    font-size: 18px;
    color: $green;
    font-weight: bold;
    border-bottom: 1px solid $white;

    &:hover,
    &.active,
    &.is-active {
      color: $green;
      border-bottom: 1px solid $green;
    }
  }

  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active {
    color: $green;
    background-color: transparent;
    border-bottom: 1px solid $green;
  }
}

@media (min-width: $tablet) {
  header {
    .address-phone {
      height: 100px;
    }

    .App-logo {
      width: 100px;
      height: 100px;
    }

    .menu-phone {
      height: 100px;
    }

    .logo-and-title {
      h1,
      h2 {
        font-size: 0.9em;
      }
    }
  }

  .nav-bar {
    .logo {
      left: 12%;
    }
  }
}

@media (min-width: $desktop) {
  header {
    // .header-container {
    //   height: 180px;
    // }

    .App-logo {
      width: 125px;
      height: 125px;
    }
  }
  .nav-bar {
    .logo {
      left: 6%;
    }

    .navbar-item {
      margin: 0 10px;
    }
  }
}

@media (min-width: $widescreen) {
  header {
    .App-logo {
      width: 235px;
      height: 235px;
    }
  }

  .nav-bar {
    .logo {
      left: 17%;
    }
  }
}

@media (min-width: $fullhd) {
}
