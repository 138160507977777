@charset "utf-8";
@import "../../node_modules/bulma/bulma.sass";
@import "./variables";

$modal-card-head-background-color: $green;

.button,
.button:visited {
  width: 100%;
  border: 1px solid $green;
  background-color: $green;
  color: #fff;
  font-size: 18px;
  border-radius: 0;
  height: 60px;
}

.button:hover,
.button:active {
  background-color: $white;
  transition: all 0.3s;
  color: $green;
  outline: none;
  border: 1px solid $green;

  svg {
    fill: $green;
  }
}

.button.long-text {
  text-align: left;
  line-height: 1em;
}

.button.reverse {
  background-color: $white;
  color: $green;
  white-space: pre-wrap;

  svg {
    fill: $green;
  }

  &:hover,
  :active {
    background-color: $green;
    color: #fff;

    svg {
      fill: $white;
    }
  }
}

.panel.is-primary {
  .panel-heading {
    background-color: $green;

    a {
      margin-left: auto;
      color: #9e0c0c;
      svg {
        vertical-align: middle;
      }
    }
  }

  .panel-block {
    align-items: flex-start;
    flex-direction: column;

    p {
      margin: 0 0 30px;
    }
    color: #000;
  }
}

.navbar-burger {
  color: $white;
}

.modal {
  .modal-card {
    .modal-card-head {
      background-color: $green;
      p,
      .modal-card-title {
        margin: 0;
        color: $white;
      }
    }
  }
}
