.w100 {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.ai-center {
  align-items: center;
}

.green {
  color: #028751;
}

.text-shadow {
  background-color: rgba($color: #fff, $alpha: 0.55);
  padding: 0.9em;
  text-shadow: 1px 0px #000;
  border-radius: 30px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-white {
  color: $white;
}

.flex-end {
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

.video {
  max-width: 100%;

  > div {
    max-width: 100%;
  }
}

.video-information {
  margin-top: 1em !important;
}

.new-badge {
  color: #9e0c0c;
  display: block;
}
